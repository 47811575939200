<template>
    <div id="Search" style="min-height: 500px;">
        <div class="search-history">
            <h4 class="t">已查閱過的<br/>提單號碼列表</h4>
            <div class="item">
                <a href="#" :class="{'active': number == current_number}" @click.prevent="toggle_current_number(number)" v-for="number in searched_numbers" :key="number">{{ number }}</a>
            </div>
        </div>
        <div class="search">
            <h3 style="font-size: 2rem;">請輸入提單號：</h3>
            <input name="key" type="text" placeholder="多筆查詢請以半形逗號 , 區隔" v-model="input_number" @keypress.enter="submit()"/>
            <div class="input" style="background-color: #015aa8;font-weight: bold;color: white;border-radius: 5px;">
                <input type="submit" style="padding: 10px 0px;" :value="is_loading? '搜尋中...':'確 認'" @click.prevent="submit()" />
            </div>
            <div class="error" v-if="error" style="color: red;text-align: center;padding: 10px 0;">
                <h3 style="color: white;">{{ current_number }}：{{ error }}</h3>
            </div>
        </div>
        <div class="search-container">
            <div class="search-result" v-if="core_waybill">
                <h2 class="t">進口貨件物流狀況</h2>
                <div class="c">
                    <div class="input"><span>提單日期</span>：<p>{{ human_time(core_waybill.created_at) }}</p></div>
                    <div class="input"><span>訂單號碼</span>：
                        <p>
                            <a :href="'https://www.t-cat.com.tw/Inquire/TraceDetail.aspx?ReturnUrl=Trace.aspx&BillID=' + core_waybill.original_number" target="_blank">
                                {{ core_waybill.original_number || '-'  }}
                            </a>
                        </p>
                    </div>
                    <div class="input"><span>提單號碼</span>：<p>{{ core_waybill.number }}</p></div>
                    <div class="input"><span>追蹤號碼</span>：<p>{{ core_waybill.tracking_number || '-'  }}</p></div>
                    <!-- <div class="input"><span>收&nbsp;件 &nbsp;人</span>：<p>{{ core_waybill.consignee_company_name }} {{ core_waybill.consignee_name }}</p></div> -->
                    <!-- <div class="input"><span>貨品內容</span>：<p>{{ core_waybill.goods_name || '-' }}</p></div> -->
                    <div class="input"><span>件 &nbsp;  &nbsp;數</span>：<p>{{ core_waybill.piece || '1' }}</p></div>
                    <div class="input" v-if="core_waybill.parent_core_waybills.length > 0">
                        <span>包 &nbsp;  &nbsp;裝</span>：
                        <p v-for="(parent_core_waybill, index) in core_waybill.parent_core_waybills" :key="parent_core_waybill.identity">
                            <a  @click="search(parent_core_waybill.number)" class="pointer">
                                <p class="inline" v-if="parent_core_waybill.material.class_name == 'House'">{{ parent_core_waybill.number }}</p>
                                <p class="inline" v-else>{{ parent_core_waybill.original_number }}</p>
                                <p class="inline" v-if="parent_core_waybill.latest_status">({{ parent_core_waybill.latest_status.name }})</p>
                                <p v-if="core_waybill.parent_core_waybills.length > 1 && index < core_waybill.parent_core_waybills.length - 1">、</p>
                            </a>
                        </p>
                    </div>
                    <div class="input" v-if="core_waybill.child_core_waybills.length > 0">
                        <span>子 &nbsp;  &nbsp;單</span>：
                        <p v-for="(child_core_waybill, index) in core_waybill.child_core_waybills" :key="child_core_waybill.identity">
                            <a  @click="search(child_core_waybill.number)" class="pointer">
                                {{ child_core_waybill.original_number }}
                                <p class="inline" v-if="child_core_waybill.latest_status">({{ child_core_waybill.latest_status.name }})</p>
                                <p v-if="core_waybill.child_core_waybills.length > 1 && index < core_waybill.child_core_waybills.length - 1">、</p>
                            </a>
                        </p>
                    </div>
                    <div class="input"><span>配送備註</span>：<p>{{ core_waybill.shipping_note || '-' }}</p></div>
                </div>
                <div class="table-line">
                    <table>
                        <thead>
                            <th>訊息時間</th>
                            <th>狀態</th>
                            <th>站所簡稱</th>
                            <th>物流訊息</th>
                        </thead>
                        <tbody>
                            <tr v-for="status in core_waybill.statuses" :key="status.identity">
                                <td>{{ human_time(status.created_at) }}</td>
                                <td>{{ status.name }}</td>
                                <td>{{ status.location }}</td>
                                <td>{{ status.description }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Search',
    data() {
        return {
            input_number: "",
            numbers: [],
            is_loading: false,
            current_number: null,
            searched_numbers: [],
            core_waybill: null,
            error: null
        }
    },
    mounted(){
        var query_number = new URL(location.href).searchParams.get('number');
        if (query_number) {
            this.search(query_number);
        }
    },
    methods: {
        submit() {
            this.numbers = this.input_number.replace(/\s/g, '').split(",");
            this.searched_numbers = this.numbers;
            this.search(this.numbers[0]);
        },
        async search(number = undefined) {
            this.input_number = "";
            this.current_number = null;
            if (number) {
                this.current_number = number;
            }
            if (this.current_number && !this.is_loading) {
                try {
                    await new Promise(resolve => {
                        this.is_loading = true;
                        this.error = null;
                        this.searched_numbers.push(this.current_number);
                        this.searched_numbers = this._.uniq(this.searched_numbers);
                        resolve();
                    });
                    await new Promise(resolve => {
                        this.core_waybill = null;
                        resolve();
                    });
                    await this.search_core_waybill();
                    await this.search_by_express_core_waybill();
                    await new Promise(resolve => {
                        // this.current_number = null;
                        this.is_loading = false;
                        resolve();
                    });
                } catch (e) {
                    await new Promise(resolve => {
                        this.is_loading = false;
                        resolve();
                    });
                }
            }
        },
        search_core_waybill() {
            return this.axios.post(
                'https://api.tw568.com/v1/guest/664e890b487ef85e-28036-39768292/core_waybills/search',
                {
                    number: this.current_number
                }
            ).then(res => {
                console.log(res);
                if (res.data.status == 'success') {
                    this.core_waybill = res.data.core_waybill;
                } else {
                    switch (res.data.errors) {
                        case "CoreWaybill not found":
                            this.error = "查無單號";
                            break;
                        default:
                            this.error = "發生錯誤：" + res.data.errors;
                            break;
                    }
                }
            }).catch(err => {
                this.error = err;
                console.log(err);
            });
        },
        toggle_current_number(number) {
            if (this.current_number != number) {
                this.search(number);
            }
        },
        reset_searched_numbers() {
            this.searched_numbers = [];
        },
        human_time(time) {
            let datetime = new Date(time);
            return datetime.format("yyyy-MM-dd hh:mm:ss");
        }
    }
}
</script>

<style scoped>
.search {
    max-width: 400px;
    margin: 0 auto;
}

.search input[type=text] {
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0 30px 0;
    width: 100%;
    border: solid 1px #015aa8;
}

.input {
    margin: 0 0 25px 0;
}

.input input[type=submit] {
    width: 100%;
    margin: 0;
}

.input p {
    border-bottom: solid 1px #ccc;
    text-align: center;
    font-size: 16px;
    flex: 2;
}

.input span {
    width: 80px;
    font-size: 16px;
    letter-spacing: 2px;
}

.search-container {
    width: 80%;
    margin-top: 50px;
    position: relative;
}

.search-result {
    float: left;
    width: 80%;
    border: solid 1px #333;
}

.table-line {
    padding-top: 5px;
    border-top: solid 1px #333;
}

.search-history {
    position: sticky;
    float: right;
    margin-bottom: 30px;
    right: 0;
    top: 0;
    width: 200px;
    border: solid 1px #333;
}

.search-history .item {
    min-height: 250px;
}

.search-history .item a {
    display: block;
    text-align: center;
    margin: 2px 0;
}

.search-history .item a:hover, .search-history .item a.active {
    background: rgb(216 0 0);
    color: #fff;
}

.reset {
    background: #015aa8;
    border-radius: 50px;
    padding: 12px;
    color: #fff;
    margin-bottom: -70px;
    cursor: pointer;
    text-align: center;
}

h2.t,
h4.t {
    background: #015aa8;
    text-align: center;
    color: #fff;
    padding: 8px;
}

.c {
    max-width: 320px;
    margin: 30px auto;
}

table {
    width: 100%;
    text-align: center;
    border-spacing: 0;
    border-collapse: collapse;
}

table thead th {
    position: relative;
    border-top: solid #333 1px;
    border-bottom: solid #333 1px;
    padding: 5px;
    background: #f0f0f0;
}

table thead th:after {
    position: absolute;
    width: 1px;
    height: 60%;
    right: -1px;
    top: 20%;
    background: #333;
    content: "";
    z-index: 2;
}

table thead th:last-child:after {
    width: 0px;
}

table tr td {
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    padding: 5px;
}

table tr td:last-child {
    border-right: 0px solid #ddd;
}

.pointer {
    cursor: pointer;
}

.inline {
    display: inline;
}

@media only screen and (max-width: 767px) {
    h2.t {
        font-size: 18px;
    }
    .table-line {
        overflow-x: scroll;
    }
    table thead th,
    table tr td {
        word-break: keep-all;
        white-space: nowrap;
    }
    .search-result {
        margin-right: 0;
        width: 100%;
    }
    .search-history {
        position: relative !important;
        width: 100%;
        margin-top: 30px;
    }
    .search-history .item {
        min-height: 50px;
    }
    .reset {
        margin: 10px;
    }
}
</style>

<template>
    <v-app>
        <v-main class="app-main">
            <v-container>
                <h1 class="header"><span style="font-size: 50px;color: #024287;">迅通國際</span> 貨件查詢</h1>
                <Search/>
                <footer>
                    <div class="footer">
                        <!-- <a>電話：(07)3457893</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a>行動：0915188119</a> -->
                        
                        <a style="float: right;">高雄市仁武區環湖路496-1號</a>
                    </div>
                </footer>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import Search from './components/Search';

export default {
    name: 'App',

    components: {
        Search,
    },

    data: () => ({
        //
    }),
};
</script>

<style scoped>
h1.header {
    color: #2f2f2f;
    font-size: 30px;
    border-bottom: 1px solid #ABCFDB;
    padding: 0px 10px 15px 45px;
    margin-bottom: 20px;
}
.footer {
    color: #024287;
    font-size: 20px;
    border-top: 1px solid #abcfdb;
    padding: 10px;
    margin-top: 20px;
}
.footer a {
    color: #024287;
}
.logo-en {
    font-size: 50px;color: rgb(216 0 0);font-style: oblique;
}
.logo-zh {
    font-size: 40px;color: rgb(0 90 169);
    margin: 8px;
}
@media only screen and (max-width: 767px) {
    .header {
        text-align: center;
    }
    .logo-en, .logo-zh {
        font-size: 30px;
    }
    .logo-word {
        display: block;
    }
}
</style>